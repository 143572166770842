import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styling/Navbar.css";
import Logo from "../assets2/logo.png";

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate(); // For redirecting after logout
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if user info exists in localStorage
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  const handleLogout = () => {
    // Remove user info from localStorage
    localStorage.removeItem("user");
    setUser(null);
    navigate("/login"); // Redirect to login page after logout
  };

  return (
    <nav className="navbar">
      <a href="/">
        <img src={Logo} alt="logo" className="logo" style={{ width: '40px', borderRadius: '20px' }} />
      </a>
      <ul className="nav-menu">
        <li className="nav-item">
          <a href="/">Home</a>
        </li>
        <li className="nav-item">
          <a href="#about">About</a>
        </li>
        <li className="nav-item">
          <a href="#services">Services</a>
        </li>
        <li className="nav-item">
          <a href="#contact">Contact</a>
        </li>
      </ul>
      {user ? (
        <div className="user-info">
          <span>Hello, {user.displayName || user.email}</span>
          <button onClick={handleLogout} className="logout-button">Logout</button>
        </div>
      ) : (
        location.pathname !== '/signup' && location.pathname !== '/login' && (
          <a href="/login" className="login-button">Login</a>
        )
      )}
    </nav>
  );
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth  } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAYkMXvG30UKCw9keJfLqzLJvplivZbHpQ",
  authDomain: "immigrant-succeed.firebaseapp.com",
  projectId: "immigrant-succeed",
  storageBucket: "immigrant-succeed.appspot.com",
  messagingSenderId: "959022830919",
  appId: "1:959022830919:web:b41a3fece3e7f86ed7e862",
  measurementId: "G-21E13N8K5L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

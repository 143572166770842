// src/App.js
import "../styling/Chatbot.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "../App.css";
import Chatbot from "./Chatbot";
import Navbar from "./Navbar";

function Home() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    async function fetchVideoIds() {
      try {
        const API_KEY = "AIzaSyAK4P86aE925e3krZw6EujBHy9i9TvFJXI"; // Replace with your actual YouTube Data API key
        const CHANNEL_ID = "UCox71ZE9ynlROFtml7ElWrA"; // Replace with the Channel ID of the desired channel
  
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=20`
        );
  
        const videos = response.data.items.map((item) => {
          let title = item.snippet.title.replace(/&#39;/g, "'"); // Replacing &#39; with '
          title = title.replace(/&amp;/g, '&'); 
          title = title.replace(/&quot;/g, '"');
          return {
            id: item.id.videoId,
            title: title,
            description: item.snippet.description, // Keep the full description
          };
        });
  
        setVideos(videos);
      } catch (error) {
        console.error("Error fetching video IDs:", error);
      }
    }
  
    fetchVideoIds();
  }, []);
  return (
    <div className="App">
      <Navbar />
      <Chatbot />

      <div className="all-content">
        <h1 className="website-title">IMMIGRANTS SUCCEED</h1>

        <div className="bigraphy">
          <p className="bi-text">
            As a first-generation low-income, refugee, who moved to the U.S. and
            struggled my way into getting to UC Berkeley, I want to share with
            the world what I've learned; so, that other immigrants (hopefully)
            don't experience the difficulties/challenges I had to go through,
            and become inspired that anything is possible, no matter how much
            one is at disadvantage!
          </p>
          <div className="image-cover">
            <div className="image" alt="Header"></div>
          </div>
        </div>
        <div className="videos-title">
          <h2>Videos</h2>
        </div>
        <div className="videos">
          {videos.map((videos, index) => (
            <div className="content-container">
              <div className="video-title">{videos.title}</div>

              <div key={index} className="video-container">
                <iframe
                  className="video"
                  width="250"
                  height="170"
                  src={`https://www.youtube.com/embed/${videos.id}`}
                  title={`YouTube video ${index}`}
                  allowFullScreen
                ></iframe>
              </div>
              <div className="desc-container">
                <p className="video-desc">
                  {videos.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <Chatbot />
      </div>
    </div>
  );
}

export default Home;

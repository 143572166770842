// src/App.js
import "./Chatbot.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import Chatbot from "./components/Chatbot";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Logout from "./components/Logout";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {


  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}

export default App;
